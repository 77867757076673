import classes from "./Invite.module.css";

const Invite = () => {
  return (
    <div>
      <div className={classes.text}>Together with their families</div>
      <div className={classes.names}>MOTHIBASEBO SIPHOSETHU</div>
      <div className={classes.and}>&</div>
      <div className={classes.names}>KGABO CAROL</div>
      <div className={classes.text}>
        invite you to join them in the celebration of their marriage on
      </div>
      <div className={classes.date}>
        <div className={classes.weddingMonth}>February</div>
        <div className={classes.weddingDay}>18</div>
        <div className={classes.weddingYear}>2023</div>
      </div>
      <div className={classes.details}>
        <div className={classes.time}>1 O'CLOCK IN THE AFTERNOON</div>
        <div className={classes.location}>Saskia Gardens, Polokwane</div>
      </div>
    </div>
  );
};

export default Invite;
