const FIREBASE_DB_DOMAIN =
  "https://wedding-app-9aa17-default-rtdb.firebaseio.com/";

export async function getGuests() {
  const response = await fetch(`${FIREBASE_DB_DOMAIN}/guestlist.json`);
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch guest list");
  }

  const transformedGuests = [];

  for (const key in data) {
    const guest = {
      id: key,
      ...data[key],
    };

    transformedGuests.push(guest);
  }

  return transformedGuests;
}

export async function getRSVP() {
  const response = await fetch(`${FIREBASE_DB_DOMAIN}/rsvp.json`);
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not fetch guest list");
  }

  const transformedGuests = [];

  for (const key in data) {
    const guest = {
      id: key,
      ...data[key],
    };

    transformedGuests.push(guest);
  }

  return transformedGuests;
}

export async function addGuest(guestData) {
  const response = await fetch(`${FIREBASE_DB_DOMAIN}/guestlist.json`, {
    method: "POST",
    body: JSON.stringify(guestData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not add guest");
  }

  return null;
}

export async function rsvpGuest(guestData) {
  const response = await fetch(`${FIREBASE_DB_DOMAIN}/rsvp.json`, {
    method: "POST",
    body: JSON.stringify(guestData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not rsvp");
  }

  return null;
}
