import { Fragment } from "react";

import classes from "./GiftRegistry.module.css";

const GiftRegistry = () => {
  return (
    <Fragment>
      <h1>Gift Registry</h1>
      <div className={classes["gift_cards"]}>
        <h3>Gift Cards</h3>
        <div className={classes.shops}>@Home</div>
        <div className={classes.shops}>Volpes</div>
        <div className={classes.shops}>CTM</div>
      </div>
      <h3>Honeymoon Fund</h3>
      <div className={classes.honeymoon}>
        <div className={classes.labels}>
          <label>Bank</label>
          <label>Account No.</label>
          <label>Branch Code</label>
          <label>Account Holder</label>
        </div>
        <div className={classes.separator}>
          <label>:</label>
          <label>:</label>
          <label>:</label>
          <label>:</label>
        </div>
        <div className={classes.info}>
          <label>Nedbank</label>
          <label>1206231726</label>
          <label>198765</label>
          <label>MST & KC Kganane</label>
        </div>
      </div>
    </Fragment>
  );
};

export default GiftRegistry;
