import { Fragment } from "react";

import classes from "./MessageByCouple.module.css";

const MessageByCouple = () => {
  return (
    <Fragment>
      <h1>Message By Couple</h1>
      <div className={classes.message}>
        <p>We cannot wait to share this special day with you</p>
        <p>
          To enjoy such a momentous occasion surrounded by our friends and
          family
        </p>
        <p>Many of you have had a hand in gettting us to this point</p>
        <p>
          Thereofore your support on this day and beyond means the world to us
        </p>
        <p>
          It will be an honor to have you there with us, physically or otherwise
        </p>
        <p>We cannot wait to share this special day with you</p>
      </div>
      <div className={classes.sign}>Mothiba & Kgabo</div>
    </Fragment>
  );
};

export default MessageByCouple;
