import { useEffect, useState } from "react";

import useHttp from "../lib/use-http";
import { getGuests, rsvpGuest } from "../lib/database-api";

import classes from "./RsvpForm.module.css";

const inviteCheck = (guests, guestName, guestSurname) => {
  for (let i = 0; i < guests.length; i++) {
    if (
      guests[i].name.toLowerCase() === guestName.trim().toLowerCase() &&
      guests[i].surname.toLowerCase() === guestSurname.trim().toLowerCase()
    ) {
      return {
        name: guests[i].name,
        surname: guests[i].surname,
        partner: guests[i].partner,
        side: guests[i].side,
      };
    }
  }
  return null;
};

const RsvpForm = () => {
  const [enteredGuestName, setEnteredGuestName] = useState("");
  const [enteredGuestSurname, setEnteredGuestSurname] = useState("");
  const [enteredGuestPartnerName, setEnteredGuestPartnerName] = useState("");
  const [enteredGuestPartnerSurname, setEnteredGuestPartnerSurname] =
    useState("");

  const [notification, setNotification] = useState(null);
  const [guestInvited, setGuestInvited] = useState(false);
  const [guestFound, setGuestFound] = useState(null);
  const [hasPartner, setHasPartner] = useState(false);

  const {
    sendRequest,
    status,
    data: guestList,
    error,
  } = useHttp(getGuests, true);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  const { sendRequest: sendRsvpRequest, status: rsvpStatus } = useHttp(
    rsvpGuest,
    false
  );

  useEffect(() => {
    if (rsvpStatus === "completed") {
      setEnteredGuestName("");
      setEnteredGuestSurname("");
      setNotification("Guest Successfully Added!");
    }
  }, [rsvpStatus]);

  if (status === "pending") {
    return <div>Loading</div>;
  }

  if (error) {
    return <div>Failed to load guest list</div>;
  }

  if (status === "completed" && (!guestList || guestList.length === 0)) {
    return <div>No Guests</div>;
  }

  const guestNameChangeHandler = (event) => {
    setEnteredGuestName(event.target.value);
    setNotification(null);
  };

  const guestSurnameChangeHandler = (event) => {
    setEnteredGuestSurname(event.target.value);
    setNotification(null);
  };

  const guestPartnerNameChangeHandler = (event) => {
    setEnteredGuestPartnerName(event.target.value);
    setNotification(null);
  };

  const guestPartnerSurnameChangeHandler = (event) => {
    setEnteredGuestPartnerSurname(event.target.value);
    setNotification(null);
  };

  const cancelHandler = () => {
    setEnteredGuestName("");
    setEnteredGuestSurname("");
    setGuestInvited(false);
    setNotification(null);
    setHasPartner(false);
  };

  const confirmHandler = () => {
    if (guestFound.partner === "no") {
      sendRsvpRequest({
        name: guestFound.name,
        surname: guestFound.surname,
        partner: guestFound.partner,
        side: guestFound.side,
      });
      return;
    }

    if (
      enteredGuestPartnerName.trim().length === 0 &&
      enteredGuestPartnerSurname.trim().length === 0
    ) {
      alert("RSVP without partner");
      sendRsvpRequest({
        name: guestFound.name,
        surname: guestFound.surname,
        partner: guestFound.partner,
        side: guestFound.side,
      });
      return;
    } else if (
      enteredGuestPartnerName.trim().length < 2 ||
      enteredGuestPartnerSurname.trim().length < 2
    ) {
      setNotification("Enter valid details name and surname for you partner");
    } else {
      sendRsvpRequest({
        name: guestFound.name,
        surname: guestFound.surname,
        partner: guestFound.partner,
        side: guestFound.side,
      });
      sendRsvpRequest({
        name: enteredGuestPartnerName,
        surname: enteredGuestPartnerSurname,
        partner: "no",
        side: guestFound.side,
      });
      return;
    }
  };

  const checkInviteHandler = () => {
    const found_guest = inviteCheck(
      guestList,
      enteredGuestName,
      enteredGuestSurname
    );

    if (!found_guest) {
      setNotification(
        "Not on guest list. Please ensure your name and surname are spelled correctly"
      );
      return;
    }

    setGuestFound(found_guest);
    setEnteredGuestName(found_guest.name);
    setEnteredGuestSurname(found_guest.surname);
    setGuestInvited(true);

    if (found_guest.partner === "yes") {
      setHasPartner(true);
    }
  };

  return (
    <div className={classes.form}>
      <div className={classes.guest}>
        <div>
          {!guestInvited && (
            <div>
              <input
                type="text"
                id="name"
                placeholder="Enter your name"
                value={enteredGuestName}
                onChange={guestNameChangeHandler}
              />
              <input
                type="text"
                id="surname"
                placeholder="Enter your surname"
                value={enteredGuestSurname}
                onChange={guestSurnameChangeHandler}
              />
            </div>
          )}
        </div>
        {guestInvited && (
          <div>
            <label>
              {enteredGuestName} {enteredGuestSurname}
            </label>
          </div>
        )}
      </div>
      {hasPartner && (
        <div className={classes["partner_message"]}>
          <div>Enter you partner's details</div>
          <div>Leave blank to RSVP without a partner</div>
        </div>
      )}
      {hasPartner && (
        <div className={classes.partner}>
          <input
            type="text"
            id="partner_name"
            placeholder="Enter you partner's name"
            value={enteredGuestPartnerName}
            onChange={guestPartnerNameChangeHandler}
          />
          <input
            type="text"
            id="partner_surname"
            placeholder="Enter you partner's surname"
            value={enteredGuestPartnerSurname}
            onChange={guestPartnerSurnameChangeHandler}
          />
        </div>
      )}
      {guestInvited && (
        <div className={classes.actions}>
          <div className={classes["action_button"]} onClick={cancelHandler}>
            Cancel
          </div>
          <div className={classes["action_button"]} onClick={confirmHandler}>
            Confirm
          </div>
        </div>
      )}
      {!guestInvited && (
        <div className={classes["action_button"]} onClick={checkInviteHandler}>
          {guestInvited ? "Submit" : "Check Invite"}
        </div>
      )}
      {notification && <div>{notification}</div>}
    </div>
  );
};

export default RsvpForm;
