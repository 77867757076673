import { Fragment } from "react";

import classes from "./Header.module.css";

import logo from "../assets/wedding_logo_dark.png";

const Header = (props) => {
  const menuShowing = props.menuShowing;
  let menu_classes = "menu";

  if (menuShowing) {
    menu_classes = "menu_showing";
  }

  return (
    <Fragment>
      <div className={classes.header}>
        <div className={classes.logo}>
          <img src={logo} alt="Mothiba weds Kagbo" />
        </div>
        <div
          className={classes[menu_classes]}
          onClick={props.onMenuClick}
        >
          <span className="material-icons">menu</span>
          <div>Menu</div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
