import { Fragment, useState } from "react";

import { auth } from "../lib/firebase";
import { onAuthStateChanged } from "firebase/auth";

import Header from "./Header";
import NavMenu from "./NavMenu";
import Display from "./Display";
import Footer from "./Footer";
import Closer from "./Closer";

const Layout = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const monitorAuthState = () => {
    onAuthStateChanged(auth, (userData) => {
      if (userData) setIsLoggedIn(true);
      else setIsLoggedIn(false);
    });
  };

  monitorAuthState();

  const [menuShowing, setMenuShowing] = useState(false);

  const toggleMenuHandler = () => {
    setMenuShowing(!menuShowing);
  };

  const closeMenuHandler = () => {
    setMenuShowing(false);
  };

  return (
    <Fragment>
      <Header menuShowing={menuShowing} onMenuClick={toggleMenuHandler} />
      {menuShowing && (
        <NavMenu loggedIn={isLoggedIn} closeMenu={closeMenuHandler} />
      )}
      <Display>{props.children}</Display>
      <Footer />
      <Closer />
    </Fragment>
  );
};

export default Layout;
