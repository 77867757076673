import { Route, Switch } from "react-router-dom";

import Invitation from "./pages/Invitation";
import Rsvp from "./pages/Rsvp";
import Layout from "./layout/Layout";
import Location from "./pages/Location";
import Registry from "./pages/Registry";
import Message from "./pages/Message";
import Login from "./pages/Login";
import AddGuest from "./pages/AddGuest";
import Guests from "./pages/Guests";

function App() {
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Invitation />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/home">
          <Invitation />
        </Route>
        <Route path="/rsvp">
          <Rsvp />
        </Route>
        <Route path="/location">
          <Location />
        </Route>
        <Route path="/registry">
          <Registry />
        </Route>
        <Route path="/message-by-couple">
          <Message />
        </Route>
        <Route path="/add-guest">
          <AddGuest />
        </Route>
        <Route path="/guests">
          <Guests />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
