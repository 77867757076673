import { Fragment } from "react";

import MapComponent from "../components/MapComponent";

import classes from "./WeddingLocation.module.css";

const WeddingLocation = () => {
  return (
    <Fragment>
      <h1>Location</h1>
      <div className={classes.map}>
        <MapComponent />
      </div>
    </Fragment>
  );
};

export default WeddingLocation;
