import { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useAuthContext } from "../store/auth-context";
import { addGuest } from "../lib/database-api";
import useHttp from "../lib/use-http";

import classes from "./AddGuest.module.css";

const AddGuest = () => {
  //Redirect user to invitation if not logged in
  const history = useHistory();
  const { currentUser } = useAuthContext();
  if (!currentUser) history.replace("/");

  const [enteredName, setEnteredName] = useState("");
  const [enteredSurname, setEnteredSurname] = useState("");
  const [chosenSide, setChosenSide] = useState("bride");
  const [partnerAllowed, setPartnerAllowed] = useState(false);

  const [notification, setNotification] = useState(null);

  const onNameChangeHandler = (event) => {
    setEnteredName(event.target.value);
    setNotification(null);
  };

  const onSurnameChangeHandler = (event) => {
    setEnteredSurname(event.target.value);
    setNotification(null);
  };

  const partnerChangeHandler = (event) => {
    if (event.target.value === "yes") setPartnerAllowed(true);
    else setPartnerAllowed(false);
  };

  const sideChangeHandler = (event) => {
    setChosenSide(event.target.value);
  };

  const { sendRequest, status } = useHttp(addGuest, false);

  useEffect(() => {
    if (status === "completed") {
      setEnteredName("");
      setEnteredSurname("");
      setPartnerAllowed(false);
      setNotification("Guest Successfully Added!");
    }
  }, [status]);

  const addGuestHandler = () => {
    if (enteredName.length === 0 || enteredSurname.length === 0) {
      setNotification("Please enter a valid name and surname");
      return;
    }

    sendRequest({
      name: enteredName,
      surname: enteredSurname,
      partner: partnerAllowed ? "yes" : "no",
      side: chosenSide,
    });
  };

  return (
    <div>
      <h1 className={classes.heading}>Add Guest</h1>
      <div className={classes.form}>
        <div className={classes.guest}>
          <input
            type="text"
            id="name"
            placeholder="Enter your name"
            onChange={onNameChangeHandler}
            value={enteredName}
          />
          <input
            type="text"
            id="surname"
            placeholder="Enter your surname"
            onChange={onSurnameChangeHandler}
            value={enteredSurname}
          />
          <select name="side" id="side" onChange={sideChangeHandler}>
            <option value="bride" selected>
              Bride
            </option>
            <option value="groom">Groom</option>
          </select>
          <select name="side" id="side" onChange={partnerChangeHandler}>
            <option value="no" selected>
              Alone
            </option>
            <option value="yes">Plus One</option>
          </select>
        </div>
        <div className={classes.submit} onClick={addGuestHandler}>
          Add Guest
        </div>
        {notification && (
          <div className={classes.notification}>{notification}</div>
        )}
      </div>
    </div>
  );
};

export default AddGuest;
