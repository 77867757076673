import { useState, useEffect } from "react";

import { useAuthContext } from "../store/auth-context";

import classes from "./Login.module.css";

const Login = () => {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");

  const [notification, setNotification] = useState(null);

  const emailChangeHandler = (event) => {
    setEnteredEmail(event.currentTarget.value);
    setNotification(null);
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.currentTarget.value);
    setNotification(null);
  };

  const { currentUser, isLoading, error, login } = useAuthContext();

  const loginSubmit = () => {
    if (enteredEmail.length === 0 || enteredPassword.length === 0) {
      setNotification("Enter valid credentials...");
      return;
    }
    login(enteredEmail, enteredPassword);
  };

  useEffect(() => {
    if (isLoading) setNotification("Loading...");
    if (error) setNotification("Could not log in");
    if (currentUser) setNotification("Login successful: " + currentUser.email);
  }, [isLoading, error, currentUser]);

  return (
    <div>
      <h1>Login</h1>
      <div className={classes.form}>
        <input
          id="email"
          placeholder="Enter your email"
          value={enteredEmail}
          onChange={emailChangeHandler}
        />
        <input
          type="password"
          id="password"
          placeholder="Enter your pasword"
          value={enteredPassword}
          onChange={passwordChangeHandler}
        />
        <div className={classes.login} onClick={loginSubmit}>
          Login
        </div>
        {notification && (
          <div className={classes.notification}>{notification}</div>
        )}
      </div>
    </div>
  );
};

export default Login;
