import classes from "./Display.module.css";

const Display = (props) => {
  return (
    <div className={classes.display}>
      <div className={classes.cushion}>C</div>
      <section className={classes.cover}>
        <div>{props.children}</div>
      </section>
      <div className={classes.cushion}>C</div>
    </div>
  );
};

export default Display;
