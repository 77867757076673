import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import classes from "./Footer.module.css";
import image from "../assets/us_bw_small.png";

const calculateTimeLeft = (wed) => {
  const wedding = `${wed.month}/${wed.day}/${wed.year}`;
  const difference = +new Date(wedding) - +new Date();

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hrs: Math.floor((difference / (1000 * 60 * 60)) % 24),
      mins: Math.floor((difference / 1000 / 60) % 60),
      secs: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const Footer = () => {
  const wedding_day = {
    year: 2023,
    month: 2,
    day: 18,
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(wedding_day));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(wedding_day));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <div className={classes["timer_parts"]}>
        <div className={classes["timer_digits"]}>{timeLeft[interval]}</div>
        <div className={classes["timer_labels"]}>{interval.toUpperCase()}</div>
      </div>
    );
  });

  const history = useHistory();

  const rsvpHandler = () => {
    history.replace("/rsvp");
  };

  return (
    <Fragment>
      <div className={classes.footer}>
        <div className={classes.RSVP} onClick={rsvpHandler}>
          RSVP
        </div>
        <div className={classes.picture}><img src={image} alt="Mothiba and Kgabo" /></div>
        <div className={classes.countdown}>
          <div className={classes.cushion}>c</div>
          <div className={classes.counter}>
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
          </div>
          <div className={classes.cushion}>c</div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
