import { useEffect } from "react";

import useHttp from "../lib/use-http";
import { getGuests } from "../lib/database-api";

const GuestList = () => {
  const { sendRequest, status, data: guests, error } = useHttp(getGuests, true);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (status === "completed" && (!guests || guests.length === 0)) {
    return <div>No Guests</div>;
  }

  return (
    <div>
      {guests &&
        guests.map((guest) => (
          <div>
            ({guest.side}) {guest.name} {guest.surname}{" "}
            {guest.partner === "yes" ? "(+1)" : ""}
          </div>
        ))}
    </div>
  );
};

export default GuestList;
