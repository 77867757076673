import { useState } from "react";

import { useHistory } from "react-router-dom";
import { useAuthContext } from "../store/auth-context";

import classes from "./Guests.module.css";
import GuestList from "../components/GuestList";
import RsvpList from "../components/RsvpList";

const Guests = () => {
  //Redirect user to invitation if not logged in
  const history = useHistory();
  const { currentUser } = useAuthContext();
  if (!currentUser) history.replace("/");

  console.log (currentUser);

  const [showInvitees, setShowInvitees] = useState(true);

  const toggleList = () => {
    setShowInvitees(!showInvitees);
  };

  return (
    <div>
      <h1>Guest List</h1>
      <div className={classes["list_button"]} onClick={toggleList}>
        {showInvitees ? "Show RSVP" : "Show Invitees"}
      </div>
      <h2>{showInvitees ? "Invitees" : "RSVP"}</h2>
      <div className={classes.list}>
        {showInvitees ? <GuestList /> : <RsvpList />}
      </div>
    </div>
  );
};

export default Guests;
