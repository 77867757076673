import { Fragment } from "react";
import { useHistory } from "react-router-dom";

import { useAuthContext } from "../store/auth-context";

import classes from "./NavMenu.module.css";

const NavMenu = (props) => {
  const loggedIn = props.loggedIn;
  const history = useHistory();

  const { currentUser, logout } = useAuthContext();

  const loginHandler = () => {
    if (loggedIn) {
      logout();
      props.closeMenu();
      return;
    }
    history.replace("/login");
    props.closeMenu();
  };

  const invitationHandler = () => {
    history.replace("/");
    props.closeMenu();
  };

  const rsvpHandler = () => {
    history.replace("/rsvp");
    props.closeMenu();
  };

  const locationHandler = () => {
    history.replace("/location");
    props.closeMenu();
  };

  const registryHandler = () => {
    history.replace("/registry");
    props.closeMenu();
  };

  const messageHandler = () => {
    history.replace("/message-by-couple");
    props.closeMenu();
  };

  const addGuestHandler = () => {
    history.replace("/add-guest");
    props.closeMenu();
  };

  const guestListHandler = () => {
    history.replace("/guests");
    props.closeMenu();
  };

  return (
    <Fragment>
      <ul className={classes.menu}>
        <li className={classes["menu_item"]} onClick={loginHandler}>
          <span className="material-icons">person</span>
          <div>{loggedIn ? "Sign Out: " + currentUser.email : "Login"}</div>
        </li>
        <li className={classes["menu_item"]} onClick={invitationHandler}>
          <span className="material-icons">mail_outline</span>
          <div>Invitation</div>
        </li>
        <li className={classes["menu_item"]} onClick={rsvpHandler}>
          <span className="material-icons">done</span>
          <div>RSVP</div>
        </li>
        <li className={classes["menu_item"]} onClick={locationHandler}>
          <span className="material-icons">pin_drop</span>
          <div>Location</div>
        </li>
        <li className={classes["menu_item"]} onClick={registryHandler}>
          <span className="material-icons">redeem</span>
          <div>Gift Registry</div>
        </li>
        <li className={classes["menu_item"]} onClick={messageHandler}>
          <span className="material-icons">sms</span>
          <div>Message From Couple</div>
        </li>
        {loggedIn && (
          <li className={classes["menu_item"]} onClick={addGuestHandler}>
            <span className="material-icons">add</span>
            <div>Add Guests</div>
          </li>
        )}
        {loggedIn && (
          <li className={classes["menu_item"]} onClick={guestListHandler}>
            <span className="material-icons">list</span>
            <div>Guest List</div>
          </li>
        )}
      </ul>
    </Fragment>
  );
};

export default NavMenu;
