import RsvpForm from "../components/RsvpForm";

const Rsvp = () => {
  return (
    <div>
      <h1>RSVP</h1>
        <RsvpForm />
    </div>
  );
};

export default Rsvp;
